<template lang="html">
  <div class="container-fluid contain" style="">
    <div>
      <img src="@/assets/images/icon_arrow_left.png" style="width: 18px" @click="$router.go(-1)"/>
      <div class="login-title" style="margin-top: 32px; margin-bottom: 20px; font-size: 30px; text-align: left">SHARE</div>
      <div class="justify-content-around align-items-center mt-4" style="display: flex; flex-direction: row; padding-top: 30px;">
        <ShareNetwork
          network="facebook"
          :url="url"
          :title="state.title"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/images/facebook.png" style="width: 70px;"/>
        </ShareNetwork>
        <ShareNetwork
          network="twitter"
          :url="url"
          :title="state.title"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/images/twitter.png" style="width: 70px;"/>
        </ShareNetwork>
        <ShareNetwork
          network="pinterest"
          :url="url"
          :title="state.title"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/images/pinterest.png" style="width: 70px;"/>
        </ShareNetwork>
      </div>
      <div class="justify-content-around align-items-center mt-4" style="display: flex; flex-direction: row;">
        <ShareNetwork
          network="email"
          :url="url"
          :title="state.title"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/images/email.png" style="width: 70px;"/>
        </ShareNetwork>
        <ShareNetwork
          network="sms"
          :url="url"
          :title="state.title"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/images/sms.png" style="width: 70px;"/>
        </ShareNetwork>
        <ShareNetwork
          network="reddit"
          :url="url"
          :title="state.title"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/images/raddit.png" style="width: 70px;"/>
        </ShareNetwork>
      </div>
      <div class="share-input">
        <input v-model="url" type="text" id="link-clipboard" style="font-size: 13px; width: 85%">
        <a style="color: #0000a8" @click="onClickCopy">COPY</a>
      </div>
    </div>
  </div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  // import moment from 'moment';
  // import {isEmpty} from 'lodash';
  // import $ from 'jquery';

  export default {
    props: ['route'],
    setup(props, {root}) {
      const data = reactive({
        url: window.location.href
      });

      const onClickCopy = () => {
        const copyElement = document.querySelector('#link-clipboard');
        copyElement.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) root.actions.alert.showSuccess({message: 'Copied to clipboard!'});
          else root.actions.alert.showError({message: 'Copied Failed'});
        } catch (e) {
          console.log(e)
        }
      };

      return {
        onClickCopy,
        ...toRefs(data)
      }
    },
    // beforeUpdate() {
    //   this.url = window.location.href;
    // },
    watch: {
      route: function (val) {
        console.log(val);
      }
    },
  }
</script>

<style lang="css" scoped>

  select {
    border: 0;
    outline: 0;
  }

  .share-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid gray;
    margin: 40px 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    margin-bottom: -40px;
  }
</style>
